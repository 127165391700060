// import { normalizeSiteRewrite } from '@sitecore-jss/sitecore-jss-nextjs';
import { Plugin } from '..';
import { normalizeOSRewrite } from 'lib/online-scheduling/utiils';

class OSDomainPlugin implements Plugin {
  exec(path: string) {
    // Remove site rewrite segment from the path
    return normalizeOSRewrite(path);
  }
}

export const osDomainPlugin = new OSDomainPlugin();
