import { Plugin } from '..';
import { normalizeCallTrackingNumber } from 'lib/affiliate/utils';

class CallTrackingNumberPlugin implements Plugin {
  exec(path: string) {
    // Remove site rewrite segment from the path
    return normalizeCallTrackingNumber(path);
  }
}

export const callTrackingNumberPlugin = new CallTrackingNumberPlugin();
