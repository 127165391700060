export const OSDOMAIN_PREFIX = '_osdomain_';
/**
 * Get site data from the rewrite path
 * @param {string} pathname the pathname
 * @returns {CallTrackingNumber} the call tracking number data from the rewrite
 */
export function getOSDomainFromRewrite(pathname: string): string {
  const path = pathname.endsWith('/') ? pathname : pathname + '/';
  const result = path.match(`${OSDOMAIN_PREFIX}(.*?)\\/`);

  if (result && result[1] !== '') {
    return result[1];
  }

  return '';
}

/**
 * Get a site rewrite path for given pathname
 * @param {string} pathname the pathname
 * @param {string} callTrackingNumber the call tracking number to use for rewrite
 * @returns {string} the rewrite path
 */
export function getOSRewrite(pathname: string, domain: string): string {
  const path = pathname.startsWith('/') ? pathname : '/' + pathname;

  return `/${OSDOMAIN_PREFIX}${domain}${path}`;
}

/**
 * Normalize a site rewrite path (remove site data)
 * @param {string} pathname the pathname
 * @returns {string} the pathname with site data removed
 */
export function normalizeOSRewrite(pathname: string): string {
  const result = pathname.match(`${OSDOMAIN_PREFIX}.*?(?:\\/|$)`);

  return result === null ? pathname : pathname.replace(result[0], '');
}
